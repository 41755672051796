import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import './Loading.scss';

class LoadingComponent extends React.Component {
    render() {
        let { children, loading } = this.props;
        return (
            <div className="root">
                <div className="wrapper">
                    {!loading && children}
                    {loading && (
                        <div className="overlay">
                            <CircularProgress className="progress" />{' '}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

// LoadingComponent.propTypes = {
//     classes: PropTypes.object.isRequired,
//     loading: PropTypes.bool,
//     children: PropTypes.any,
// };

//export default withStyles(styles)(LoadingComponent);
export default LoadingComponent;