import React, { Component } from 'react';
import { Route } from 'react-router';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import Clients from './../Pages/Clients';
import Configurations from './../Pages/Configurations';

export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    let isAuthenticated = localStorage.getItem('isAuthenticated');

    if (!isAuthenticated) {
      this.props.history.push('/');
    }

    return (
      <div>
        <NavMenu />
        <Container>
          <Route exact path='/sistema' component={Clients} />
          <Route path='/sistema/configurations' component={Configurations} />
        </Container>
      </div>
    );
  }
}