import React, { Component } from 'react';
import { Button, Row, Col, Form, FormGroup, Input } from 'reactstrap';
import http from '../httpService';
import './Login.scss';
import LoadingComponent from '../components/Loading';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            firstLoading: true,
            email: '',
            senha: '',
            alias: (localStorage.getItem('alias') || "0"),
            errorMensage: '',
            clients: [],
            logoLastUpdate: '',
            backgroundImageLastUpdate: ''
        };

        localStorage.setItem('isAuthenticated', '');

        this.login = this.login.bind(this);
    }

    focusInput = (component) => {
        if (component) {
            component.focus();
        }
    };

    componentDidMount() {
        http.get("configurations/clients")
            .then((result) => {
                this.setState({
                    firstLoading: false,
                    loading: false,
                    clients: result.data.clients,
                    logoLastUpdate: result.data.logoLastUpdate,
                    backgroundImageLastUpdate: result.data.backgroundImageLastUpdate
                });
                // Verifica se o ultimo alias logado ainda existe
                if (this.state.alias !== "0" && result.data.clients.find(x => x.id.toString() === this.state.alias) == null) {
                    this.setState({ alias: "0" });
                }
            })
            .catch(() => {
                this.setState({ loading: false, errorMensage: 'Não foi possível comunicar com o servidor!' });
            });
        // http.get("configurations/logo?timestamp=123")
        //     .then((result) => {
        //         //console.log(result.data);
        //     });
    }

    handleChange = async (event) => {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;
        await this.setState({
            [name]: value,
        });
    }

    login(e) {
        // Cancela o Refresh da pagina
        e.preventDefault();

        if (this.state.alias === "0") {
            this.setState({ errorMensage: 'Selecione uma base' });
            return;
        }
        this.setState({ loading: true, errorMensage: '' });
        http.post('clients/login/' + this.state.alias, this.state)
            .then((result) => {
                window.location.href = result.data;
                // Salva o Alias
                localStorage.setItem('alias', this.state.alias);
                // Loga o usuário no sistema
                if (this.state.alias === "1") {
                    localStorage.setItem('isAuthenticated', "true");
                }
            })
            .catch(() => {
                this.setState({ loading: false, errorMensage: 'Usuário e/ou senha inválidos' });
            });
    }

    render() {
        return (
            <LoadingComponent loading={this.state.firstLoading}>
                <div className="fundo" style={{ backgroundImage: "url(api/configurations/backgroundImage?" + this.state.backgroundImageLastUpdate + ")" }}>
                    <div className="main">
                        <div className="contant">
                            <div className="panel">
                                <Row>
                                    <Col xs="12" className="text-center">
                                        <img alt="Logo" className="imagem" src={'api/configurations/logo?' + this.state.logoLastUpdate} />
                                    </Col>
                                </Row>
                                <Row className="marginTop30">
                                    <Col xs="12">
                                        <Form onSubmit={this.login}>
                                            <FormGroup>
                                                <Input onChange={this.handleChange} value={this.state.email} type="text" name="email" id="email" placeholder="Nome de usuário ou e-mail" autoFocus />
                                            </FormGroup>
                                            <FormGroup>
                                                <Input onChange={this.handleChange} value={this.state.senha} type="password" name="senha" id="exampleSenha" placeholder="Senha" />
                                            </FormGroup>
                                            <FormGroup>
                                                <Input value={this.state.alias} onChange={this.handleChange} type="select" name="alias" id="exampleSelect">
                                                    <option value="0">Selecione uma base</option>
                                                    {this.state.clients.map((client, index) =>
                                                        <option key={index} value={client.id}>{client.name}</option>
                                                    )}
                                                </Input>
                                            </FormGroup>
                                            <Button className="btn-block" color="primary">Entrar</Button>
                                        </Form>
                                    </Col>
                                </Row>
                                <Row >
                                    <Col xs="12" className="text-center marginTop30" style={{ color: 'red' }}>
                                        {this.state.errorMensage}
                                    </Col>
                                </Row>
                                <LoadingComponent loading={this.state.loading}></LoadingComponent>
                            </div>
                        </div>
                    </div>
                </div>
            </LoadingComponent >
        );
    }
}

export default Login;