import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import Login from './Pages/Login';

import './scss/bootstrap.scss';
import './scss/app.scss';

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <div>
        <Route path='/' exact component={Login} />
        <Route path='/sistema' component={Layout} />
      </div>
    );
  }
}