import axios from 'axios';

const http = axios.create({
    baseURL: 'api/',
    timeout: 120000,
});

// http.interceptors.request.use(
//     config => {

//         if (authStorage.isAuthenticated) {
//             // eslint-disable-next-line no-param-reassign
//             config.headers.common.Authorization = `Bearer ${authStorage.getLocalToken()}`;
//         }

//         // Esta opção irá permitir o envio e recebimento de Cookie através do Cors
//         // Cookie é utilizado para definição da linguagem do Usuário 
//         config.withCredentials = true;
//         // TODO: Verificar pq precisa desta configuração
//         config.headers.common['X-Requested-With'] = 'XMLHttpRequest';

//         return config;
//     },
//     error => Promise.reject(error),
// );

// http.interceptors.response.use(
//     response => {
//         authStorage.setDataUltimaRequisicao();
//         return response;
//     },
//     async error => {

//         // Tenta verificar se foi erro de autenticação 401
//         try {
//             const { config, response: { status } } = error
//             const originalRequest = config

//             if (status === 401) {

//                 if (!isAlreadyFetchingAccessToken) {
//                     isAlreadyFetchingAccessToken = true;

//                     const data = authStorage.getLocalJwt();
//                     if (data) {

//                         const dataUltimaRequisicao = authStorage.getDataUltimaRequisicao();

//                         // Calcula a diferença em minutos 
//                         var diferenca = new Date() - dataUltimaRequisicao;
//                         var diferencaData = new Date(diferenca)
//                         var diferencaEmMinutos = (diferencaData.getTime() / 1000) / 60;

//                         if (data.expiraTokenMinutos != null && diferencaEmMinutos >= data.expiraTokenMinutos) {
//                             return axios.post(`/Home/Logout`, {
//                                 refreshToken: data.refreshToken
//                             }).then(() => {
//                                 // TODO: Faz o LogOff
//                                 authStorage.Logout();
//                                 window.location.href = "/";
//                             });
//                         }

//                         axios.post(`/Home/ObterRefreshToken`, {
//                             refreshToken: data.refreshToken
//                         }).then((response) => {
//                             authStorage.setLocalJwt(response.data);
//                             isAlreadyFetchingAccessToken = false;
//                             onAccessTokenFetched(response.data.token);
//                         }).catch((error) => {
//                             // TODO: Faz o LogOff
//                             authStorage.Logout();
//                             window.location.href = "/";
//                         });
//                     } else {
//                         // TODO: Faz o LogOff
//                         authStorage.Logout();
//                         window.location.href = "/";
//                     }
//                 }

//                 const retryOriginalRequest = new Promise((resolve) => {
//                     addSubscriber(access_token => {
//                         originalRequest.headers.Authorization = 'Bearer ' + access_token
//                         resolve(axios(originalRequest))
//                     })
//                 });

//                 return retryOriginalRequest
//             }
//         } catch (error) { }

//         return Promise.reject(error);
//     }
// )

export default http;