import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import http from '../httpService';
import { Container, Row, Col } from 'reactstrap';

class Clients extends Component {
    constructor(props) {
        super(props);
        this.state = {
            itens: [],
            loading: true,
            modal: false,
            id: '',
            name: '',
            link: '',
            email: '',
            senha: '',
            active: true,
        };

        this.updateList = this.updateList.bind(this);
        this.toggle = this.toggle.bind(this);
        this.save = this.save.bind(this);
        this.delete = this.delete.bind(this);

        this.handleChange = this.handleChange.bind(this);
        //this.edit = this.edit.bind(this);
    }

    componentDidMount() {
        this.updateList();
    }

    updateList() {
        http.get('clients')
            .then((response) => {
                this.setState({ itens: response.data });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    save(e) {
        // Cancela o Refresh da pagina
        e.preventDefault();

        this.setState({ loading: true });

        http.post('clients', this.state)
            .finally(() => {
                this.toggle();
                this.updateList();
            });
    }

    delete() {
        this.setState({ loading: true });

        http.delete('clients/' + this.state.id)
            .catch(error => {
                alert("Não é permitido excluir o 'cliente' de configuração!");
            })
            .finally(() => {
                this.toggle();
                this.updateList();
            });

    }

    handleChange = async (event) => {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;
        await this.setState({
            [name]: value,
        });
    }

    newItem() {
        this.setState({
            id: 0,
            name: '',
            link: '',
            email: '',
            senha: '',
            active: true
        });

        this.toggle();
    }

    edit(id) {
        let item = this.state.itens.find(x => x.id === id);

        this.setState({
            id: item.id,
            name: item.name,
            link: item.link || '',
            email: item.email || '',
            senha: item.senha || '',
            active: item.active
        });

        this.toggle();
    }

    renderModal() {
        return (
            <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                <ModalHeader toggle={this.toggle}>Cliente</ModalHeader>
                <ModalBody>
                    <Form id="formCLient" onSubmit={this.save}>
                        <FormGroup>
                            <Label for="exampleName">Nome</Label>
                            <Input onChange={this.handleChange} value={this.state.name} type="text" required name="name" id="exampleName" placeholder="Digite o Nome que será exibido na lista" />
                        </FormGroup>
                        <FormGroup>
                            <Label for="exampleLink">Link</Label>
                            <Input onChange={this.handleChange} value={this.state.link} type="text" required name="link" id="exampleLink" placeholder="https://cliente.stratecsoftware.com" />
                        </FormGroup>
                        <FormGroup>
                            <Label for="exampleEmail">Email</Label>
                            <Input onChange={this.handleChange} value={this.state.email} type="text" required name="email" id="exampleEmail" placeholder="E-mail/Usuário" />
                        </FormGroup>
                        <FormGroup>
                            <Label for="exampleSenha">Senha</Label>
                            <Input onChange={this.handleChange} value={this.state.senha} type="password" required name="senha" id="exampleSenha" placeholder="Senha do usuário" />
                        </FormGroup>

                        <FormGroup check>
                            <Label check>
                                <Input onChange={this.handleChange} defaultChecked={this.state.active} name="active" type="checkbox" />{' '}
                                Ativo
                            </Label>
                        </FormGroup>
                    </Form>

                </ModalBody>
                <ModalFooter>
                    {this.state.id !== 0 && <Button color="danger" className="float-left" onClick={this.delete}>Excluir</Button>}
                    <Button type="submit" color="primary" form="formCLient">Salvar</Button>{' '}
                    <Button color="secondary" onClick={this.toggle}>Cancelar</Button>
                </ModalFooter>
            </Modal>
        );
    }

    render() {
        let content = this.renderModal();
        return (
            <Container>
                {content}
                <Row>
                    <Col xs="12">
                        <h1>Clientes</h1>
                        <p>Lista de Clientes</p>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col xs="12" className="text-right">
                        <button type="button" className="btn btn-primary" onClick={this.newItem.bind(this)}>
                            Adicionar
                        </button>
                    </Col>
                </Row>
                <table className='table table-striped table-click'>
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Link</th>
                            <th>E-mail</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.itens.map(client =>
                            <tr key={client.id} style={{ color: ((client.active) ? 'green' : 'red') }} onClick={this.edit.bind(this, client.id)}>
                                <td>{client.name}</td>
                                <td>{client.link}</td>
                                <td>{client.email}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </Container>
        );
    }
}

export default Clients;