import React, { Component } from 'react';
import { Button, Form, FormGroup, FormText, Label, Input } from 'reactstrap';
import http from '../httpService';
import { Container, Row, Col } from 'reactstrap';

class Configurations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            logo: null,
            backgroundImage: null,
            senhaAntiga: '',
            novaSenha: '',
            confirmarNovaSenha: '',
            message: ''
        };

        this.handleChange = this.handleChange.bind(this);
    }

    save() {

        const fd = new FormData();
        fd.append('backgroundImage', this.state.backgroundImage);
        fd.append('logo', this.state.logo);
        fd.append('senhaAntiga', this.state.senhaAntiga);
        fd.append('novaSenha', this.state.novaSenha);
        fd.append('confirmarNovaSenha', this.state.confirmarNovaSenha);

        http.post('configurations', fd)
            .then(() => {
                this.setState({ message: 'Salvo com sucesso!' });
            })
            .catch((error) => {
                this.setState({ message: 'Ocorreu algum erro, verifique todos os campos!' });
            })
    }

    handleChange = async (event) => {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;
        await this.setState({
            [name]: value,
        });
    }

    uploadFile = event => {
        const { target } = event;
        const { name } = target;
        this.setState({ [name]: event.target.files[0] });
    }

    render() {
        return (
            <Container>
                <Row>
                    <Col xs="12">
                        <h1>Configurações</h1>
                        <p>Configurações da Pagina de Login Unificada</p>
                    </Col>
                </Row>
                <hr />
                <Form>
                    <Row>
                        <Col xs="4">
                            <FormGroup>
                                <Label for="senhaAntiga">Senha Antiga</Label>
                                <Input type="password" onChange={this.handleChange} value={this.state.senhaAntiga} name="senhaAntiga" id="senhaAntiga" placeholder="" />
                            </FormGroup>
                        </Col>
                        <Col xs="4">
                            <FormGroup>
                                <Label for="novaSenha">Nova Senha</Label>
                                <Input type="password" onChange={this.handleChange} value={this.state.novaSenha} name="novaSenha" id="novaSenha" placeholder="" />
                            </FormGroup>
                        </Col>
                        <Col xs="4">
                            <FormGroup>
                                <Label for="confirmarNovaSenha">Confirmar Nova Senha</Label>
                                <Input type="password" onChange={this.handleChange} value={this.state.confirmarNovaSenha} name="confirmarNovaSenha" id="confirmarNovaSenha" placeholder="" />
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col xs="6">
                            <FormGroup>
                                <Label for="exampleFile">Imagem de Fundo</Label>
                                <Input type="file" name="backgroundImage" id="backgroundImage" onChange={this.uploadFile} />
                                <FormText color="muted">
                                    Imagem de Fundo que será exibida na página de Login Unificado.
                                </FormText>
                            </FormGroup>
                        </Col>
                        <Col xs="6">
                            <FormGroup>
                                <Label for="exampleFile">Logo</Label>
                                <Input type="file" name="logo" id="logo" onChange={this.uploadFile} />
                                <FormText color="muted">
                                    Logo que será exibida na página de Login Unificado.
                                </FormText>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" className="text-right">
                            <Button color="success" onClick={this.save.bind(this)}>Salvar</Button>
                        </Col>
                    </Row>
                </Form>
                <br />
                {this.state.message}
            </Container>
        );
    }
}

export default Configurations;